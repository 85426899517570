import React from 'react';
import GoogleMap from './GoogleMap';
import Logo from './Logo';
import { Link } from 'gatsby';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <Logo></Logo>
            <div className="mt-48">
              <a
                className="link light small mb-24"
                href="mailto:info@keylabs.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                info@keylabs.io
              </a>
              <p className="footer-copyright">
                © Keylabs. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
