import React from 'react';
import Logo from './Logo';
import { Navbar, Nav } from 'react-bootstrap';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const Header = () => {
  return (
    <header className="page-header">
      <div className="container">
        <div className="row">
          <div className="col">
            <Navbar expand="lg">
              <Navbar.Brand>
                <Logo></Logo>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav">
                <svg
                  className="icon"
                  width="36"
                  height="36"
                  viewBox="0 0 24 24"
                >
                  <path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
                </svg>
              </Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav>
                  <AnchorLink className="nav-link" to="/#home" title="Home" />
                  <AnchorLink className="nav-link" to="/#pricing" title="Pricing" />
                  <AnchorLink
                    className="nav-link"
                    to="/#trainings"
                    title="Trainings"
                  />
                  <AnchorLink
                    className="nav-link"
                    to="/#services"
                    title="Services"
                  />
                  {/* <Nav.Link href="#home">Home</Nav.Link>
                  <Nav.Link href="#pricing">Pricing</Nav.Link>
                  <Nav.Link href="#trainings">Trainings</Nav.Link>
                  <Nav.Link href="#services">Services</Nav.Link> */}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
